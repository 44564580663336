import { environment } from 'environments/environment';

export const getFullUrtForFile = (url: string | undefined): string => {
  if (!url) {
    return '';
  }
  if (url.includes('data:image') || url.includes(environment.apiUrlImg)) {
    return url;
  }
  if (url.includes('/storage/')) {
    return `${environment.apiUrlImg}${url}`;
  }
  return `${environment.apiUrlImg}/storage/${url}`;
};
